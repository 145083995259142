import Container from "@mui/material/Container";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import {
  GetLensReviewDocument,
  GetQuestionnaireTableDocument,
  useGetQuestionnaireQuery,
  useUpdateQuestionnaireTableMutation,
} from "../../generated";
import { Button, Switch, Typography } from "@mui/material";
import Loader from "../common/Loader";
import AnswerQuestionnaireModal from "./AnswerQuestionnaireModal";
import Question from "./Question";
import { useQueue } from "../../hooks/useQueue";
import { useLazyQuery } from "@apollo/client";
import { useSubmitEmail } from "../../hooks/useSubmitEmail";

interface WorkloadsStageDictionary {
  [key: string]: string;
}

interface EmailSubmittedDictionary {
  [key: string]: boolean;
}

interface RiskCountDictionary {
  [key: string]: number;
}

interface RiskCount {
  hriCount: number;
  initialHriCount: number;
}

interface AnswerQuestionnairedProps {
  arn: string;
  organisation: any;
  createdWorkloads: any;
  riskCounts: RiskCount;
  halfStageHriCount: number;
  riskCountsDict: any;
  firstRiskCountsDict: any;
  setRiskCountsDict: any;
  stateStage: any;
  setStateStage: any;
  changeTab: (index: number) => void;
  setUnlockStep2: (index: boolean) => void;
  process: string;
}

function createData(
  id: string,
  description: string,
  status: string,
  updateStatus: string,
  reducedHri: number
) {
  return { id, description, status, updateStatus, reducedHri };
}

function createAnswerData(
  id: string,
  description: string,
  status: string[],
  updateStatus: string,
  reducedHri: number
) {
  return { id, description, status, updateStatus, reducedHri };
}

type RowQuestion = {
  id: string;
  description: string;
  status: string;
  updateStatus: string;
  reducedHri: number;
};

type AnswerQuestion = {
  id: string;
  description: string;
  status: string[];
  updateStatus: string;
  reducedHri: number;
};

export default function AnswerQuestionnaire({
  arn,
  organisation,
  createdWorkloads,
  riskCounts,
  halfStageHriCount,
  riskCountsDict,
  firstRiskCountsDict,
  setRiskCountsDict,
  stateStage,
  setStateStage,
  changeTab,
  setUnlockStep2,
  process,
}: AnswerQuestionnairedProps) {
  const { addToQueue } = useQueue({ arn });
  const [rows, setRows] = useState<Array<RowQuestion>>([]);
  const [answer, setAnswer] = useState<Array<AnswerQuestion>>([]);
  const [emailSubmitted, setEmailSubmitted] = useState<EmailSubmittedDictionary>({});
  const [questionnaireAnswer, setQuestionnaireAnswer] = useState({});
  const [switchBehavior, setSwitchBehavior] = useState("All");
  const [questionnaireSpinner, setQuestionnaireSpinner] = useState(false);

  const { data, loading } = useGetQuestionnaireQuery({
    context: {
      apiName: "well_architected",
    },
  });
  
  const { submitemail_mutation } = useSubmitEmail({ arn, emailSubmitted, setEmailSubmitted });

  const [getLensReview] = useLazyQuery(GetLensReviewDocument,{
    context: {
      apiName: "well_architected",
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const [updateQuestionnaireTableMutation] = useUpdateQuestionnaireTableMutation();

  const [ getQuestionnaireTable ] = useLazyQuery(GetQuestionnaireTableDocument,{
    onCompleted: (questionnaireData) => {
      const response = JSON.parse(questionnaireData.getQuestionnaireTable);
      setQuestionnaireAnswer(
        response
      );
      const updatedAnswer = answer.map(question => {
        if (response[question.id]) {
            // Update the status property
            return { ...question, status: [response[question.id]] };
        }
        return question;
      });
      // Update the answer state with the modified array
      setAnswer(updatedAnswer);
      // Can stop the spinner now
      setQuestionnaireSpinner(true);
    }
  });

  const addWorkloadStageKeyValuePairs = (newPairs: WorkloadsStageDictionary) => {
    setStateStage((stateStage: any) => ({
      ...stateStage,
      ...newPairs
    }));
  };

  const addRiskKeyValuePairs = (newPairs: RiskCountDictionary) => {
    setRiskCountsDict((riskCountsDict: any) => ({
      ...riskCountsDict,
      ...newPairs
    }));
  };

  const addEmailSubmittedKeyValuePairs = (newPairs: EmailSubmittedDictionary) => {
    setEmailSubmitted((emailSubmitted: any) => ({
      ...emailSubmitted,
      ...newPairs
    }));
};

  const getHriResult = async (workloadid: string) => {
    const lensReview = await getLensReview({
      variables: {
        arn: arn,
        workloadid: workloadid,
        lens: "wellarchitected",
      },
    });
    let hri = 0;
    hri = lensReview?.data?.getLensReview?.PillarReviewSummaries?.map(
      (element: any) => {
        return element?.RiskCounts?.HIGH;
      }
    ).reduce((pre: number, cur: number) => {
      return pre + cur;
    }, 0);
    return hri
  };

  useEffect(() => {
    if (!loading && data?.getQuestionnaire) {
      setUnlockStep2(false);
      setRows(
        data?.getQuestionnaire.map((q: any) => {
          return createData(q.id, q.description, "", "INITIAL", 0);
        })
      );
      setAnswer(
        data?.getQuestionnaire.map((q: any) => {
          return createAnswerData(q.id, q.description, [], "INITIAL", 0);
        })
      );
      const promises = Object.entries(createdWorkloads).map(async ([key, value]) => {
        let hri = await Promise.resolve(getHriResult(typeof value === "string" ? value: ''));
        await getQuestionnaireTable({
          variables: {
            arn: arn,
            workloadid: typeof value === "string" ? value: '',// value for 'workloadid'
          },
          context: {
            apiName: "well_architected",
          },
        });
        if (hri !== undefined) {
          addRiskKeyValuePairs({[typeof value === "string" ? value: '']:hri})
          addEmailSubmittedKeyValuePairs({[typeof value === "string" ? value: '']:false});
        }
      });
      Promise.all(promises);
    }
  }, [data, loading, createdWorkloads]);

  const processAnswer = async (fn: any) => {
    addToQueue(fn);
  };
  
  if(loading){
    window.scrollTo({ top: 0, behavior: 'smooth' }) //scroll to the top of the page
  }
  
  const updateAnswer = async (row: RowQuestion, answers: string[]) =>{
    const updatedAnswer = answer.map(question => {
      if (question.id === row.id) {
          // Update the status property
          return { ...question, status: answers, updateStatus: row.updateStatus };
      }
      return question;
    });
    // Update the answer state with the modified array
    setAnswer(updatedAnswer);
  }

  const updateQuestionnaireTable = async (workloadid: string, questionid: string, answer: string) =>{
    await updateQuestionnaireTableMutation({
      variables: {
        arn: arn,
        workloadid: workloadid,// value for 'workloadid'
        questionid: questionid,// value for 'questionid'
        answer: answer,// value for 'answer'
      },
      context: {
        apiName: "well_architected",
      },
    });
  }

  const updateRow = async (index: number, row: RowQuestion, workloadid: string) => {
    let hri = await getHriResult(workloadid);
    if (hri !== undefined) {
      addRiskKeyValuePairs({[workloadid]:hri})
    }
    updateQuestionnaireTable(workloadid, row.id, row.status);
    if (riskCounts.hriCount <= halfStageHriCount / 2 && stateStage[workloadid] !== "HALF_STAGE") {
      addWorkloadStageKeyValuePairs({[workloadid]:"HALF_STAGE"});
      if(emailSubmitted[workloadid] === false){
        await Promise.all([
          submitemail_mutation(workloadid),
        ]);
      }
    }
  };

  const handleSwitch = () => {
    setSwitchBehavior((prev) => (prev === 'All' ? 'Independent' : 'All')); // Toggle between "All" and "Independent"
  };

  const csvLines = (lines: any) => {
    return lines
      .map((e: any) => {
        return e.join(",");
      })
      .join("\r\n");
  };

  const exportCsv = () => {
    let header = ['ID', 'Description']
    Object.entries(createdWorkloads).map(([key, value]) => {
      let name = key.split(organisation+"-").join('');
      header.push(name)
    })
    let csvFile = new Blob(
      [
        csvLines([
          header,
        ]) +
          "\r\n" +
          csvLines(
            answer.map((question: any) => [
              question["id"],
              JSON.stringify(question["description"]),
              question["status"].join(","),
            ])
          ),
      ],
      {
        type: "text/csv",
      }
    );
    const name = `WAFR_People_Policy_and_Tools_${Date.now()}.csv`;
    let link = document.createElement("a");
    link.href = URL.createObjectURL(csvFile);

    link.setAttribute("visibility", "hidden");
    link.download = name;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }; 

  return (
    <Container maxWidth="lg" component={Paper}>
      <Grid container sx={{ p: 1 }}>
        {
          Object.keys(createdWorkloads).length !== 1 ? 
          (
          <Grid item xs md={12}>
            <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
              <Typography fontWeight="bold" noWrap>
                Answer Questions Independently?
              </Typography>
              <Typography fontWeight="bold" noWrap>
                &nbsp;
              </Typography>
              <Switch
                checked={switchBehavior === 'Independent'} // Set checked state based on switchBehavior
                onChange={handleSwitch} // Handle switch toggling
                color="success"
              />
            </Box>
          </Grid>
          )
          : null
        }
      </Grid>
      <Grid container sx={{ p: 1 }}>
        <Typography>
          NB: You will need to answer all questions in the one sitting due to
          the way that the workload is updated. If you would like any
          assistance, please reach out to secure@6pillars.ai
        </Typography>
      </Grid>
      <Grid item xs md={4}></Grid>
      <Grid
        container
        sx={{ pb: 2, pt: 2, pl: 2 }}
        spacing={2}
        alignItems="center"
      >
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650, pt:10 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Question</TableCell>
                {
                  Object.entries(createdWorkloads).map(([key, value]) => {
                    let workloadid = typeof value === "string" ? value: '';
                    let name = key.split(organisation+"-").join('');
                    let percentage = Math.round(
                      ((firstRiskCountsDict[workloadid]-riskCountsDict[workloadid])/firstRiskCountsDict[workloadid]) * 100
                    );
                    return(
                      <TableCell key={key} align="center">
                        {name}
                        <br></br>
                        {riskCountsDict[workloadid]}/{firstRiskCountsDict[workloadid]}({percentage}%) 
                      </TableCell>
                    );
                  })
                }
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length === 0 && (
                <TableRow
                  key={1}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell colSpan={3}>
                    <Loader></Loader>
                  </TableCell>
                </TableRow>
              )}
              {rows.map((row, index) => (
                <Question
                  key={index}
                  arn={arn}
                  createdWorkloads={createdWorkloads}
                  index={index}
                  row={row}
                  updateRow={updateRow}
                  updateAnswer={updateAnswer}
                  processAnswer={processAnswer}
                  switchBehavior={switchBehavior}
                  questionnaireAnswer={questionnaireAnswer}
                  process={process}
                  questionnaireSpinner={questionnaireSpinner}
                ></Question>
              ))}
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                {
                  Object.entries(createdWorkloads).map(([key, value]) => {
                    let workloadid = typeof value === "string" ? value: '';
                    let percentage = Math.round(
                      ((firstRiskCountsDict[workloadid]-riskCountsDict[workloadid])/firstRiskCountsDict[workloadid]) * 100
                    );
                    return(
                      <TableCell key={key} align="center">
                        {riskCountsDict[workloadid]}/{firstRiskCountsDict[workloadid]}({percentage}%) 
                      </TableCell>
                    );
                  })
                }
                <TableCell></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ display: "flex", pt: 3, direction: "row" }}>
          <Grid item xs md={8}>
            <Typography sx={{ pl: 1, pr: 2 }}>
              Click to submit your Well-Architected Framework Review
            </Typography>
          </Grid>
          <Grid item xs md={2} sx={{pr: 4}}>
            <Button
              onClick={exportCsv}
              type="submit"
              variant="contained"
              sx={{
                width: 157,
                height: 40,
                bgcolor: "secondary.main",
                "&:hover": {
                  bgcolor: "secondary.main",
                  color: "secondary.contrastText",
                },
              }}
            >
              Download
            </Button>
          </Grid>
          <Grid item xs md={2} sx={{pl: 4}}>
            <AnswerQuestionnaireModal
              arn={arn}
              rows={rows}
              createdWorkloads={createdWorkloads}
              changeTab={changeTab}
            ></AnswerQuestionnaireModal>
          </Grid>
        </Box>
      </Grid>
    </Container>
  );
}
