import {
    useGetLambdaCreationResultLazyQuery,
    OnResultDocument,
    GetRedisDataDocument,
} from "../generated";
  
import { useApolloClient } from "@apollo/client";
import { useEffect } from "react";

const useAutomationSubscription = ({ arn, prompt, setLambdaCode, setLoading }: any) => {
  const client = useApolloClient();

  const [
    getLambdaCreationResult,
    { data, loading, called },
  ] = useGetLambdaCreationResultLazyQuery({
    variables: {
      arn: arn,
      customRemediation: false,
      prompt: prompt,
    },
  });

  useEffect(() => {
    if (data?.getLambdaCreationResult?.output) {
      console.log("Subscribing 1")
      client
        ?.subscribe({
          query: OnResultDocument,
          variables: {
            id: data?.getLambdaCreationResult.output,
          },
        })
        .subscribe(
          ({ data }: any) => {
            console.log("subscribing 2")
            const splitted_arn = arn.split(":");
            const new_key = `aws-automation-${splitted_arn[4]}`;
            client
              ?.query({
                query: GetRedisDataDocument,
                variables: {
                  key: data?.onResult?.result,
                  move_to_cache: false,
                  new_key,
                },
              })
              .then(async ({ data }) => {
                const urlInfo = JSON.parse(data?.getRedisData);
                let res = await fetch(urlInfo.url);
                let automationJson = await res.json();
                setLambdaCode(automationJson.code);
                setLoading(false);
              });
          },
          (error: any) => {
            console.log(error);
          },
          () => {
            console.log("completed subscription");
          }
        );
    }
  }, [data, called, getLambdaCreationResult]);

  return { loading, getLambdaCreationResult };
};

export default useAutomationSubscription;