import { Box, Collapse, IconButton, Switch, TableCell, TableRow, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useGetAwsControlQuery } from "../../generated";
import ConnectedAccount from "../../models/ConnectedAccount";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import ComplianceStatus from "../common/ComplianceStatus";
import Loader from "../common/Loader";

interface QuestionBestPracticeProps {
  technicalReviewArn: string;
  index: number;
  accounttype: string;
  rows: any;
  row: any;
  rowSelection: any;
  setRowSelection: any;
  rowNames: any;
  setRowNames: any;
  failedRows: string[];
  setFailedRows: React.Dispatch<React.SetStateAction<string[]>>;
  switchBehavior: any;
  process: string;
  connectedAccounts: Array<ConnectedAccount>
  activeSpinner: boolean;
}

export default function QuestionBestPractice({
  technicalReviewArn,
  index,
  accounttype,
  rows,
  row,
  rowSelection,
  setRowSelection,
  rowNames,
  setRowNames,
  failedRows,
  setFailedRows,
  switchBehavior,
  process,
  connectedAccounts,
  activeSpinner,
}: QuestionBestPracticeProps){

  const [open, setOpen] = useState(false);
  let failedFinding = false;

  //Query to get the sec hub findings
  const { data, loading } = useGetAwsControlQuery({
    variables: {
      arn: technicalReviewArn,
      controlname: row.controlname,
      connectedAccounts: connectedAccounts,
    },
  });

  //MAR - Multi Automated Remediation
  //RowSelection displays all the rows that have been selected in the MAR Modal
  //RowNames keeps track of which rows were selected and passes it to the updateBestPracticeMutation
  //SetFailedRows adds controls that have a failed finding to the array and passes it to the MAR Modal
  const handleQuestionAnswer = (row:any) => {
    if(rowSelection.includes(row)){
      setRowSelection(rowSelection.filter(
        (r:any) => r.controlname !== row.controlname,
      ));
      setRowNames(rowNames.filter(
        (n:any) => n !== row.controlname,
      ));
      if (failedFinding === true) {
        setFailedRows(failedRows.filter(
          (n:any) => n !== row.controlname,
        ));
      }
    } else {
      setRowSelection([
        ...rowSelection,row
      ]);
      setRowNames([
        ...rowNames,row.controlname
      ]);
      if (failedFinding === true) {
        setFailedRows([
          ...failedRows,row.controlname
        ]);
      }
    }
  };

  //This useEffect triggers if Select All Recommendations? is selected
  useEffect(() => {
    if (switchBehavior === 'All') {
      setRowSelection(rows);
      setRowNames(rows.map((row:any) => row.controlname));
      if (failedFinding === true) {
        setFailedRows((i) => [...i, row.controlname])
      }
    } else {
      setRowSelection([]);
      setRowNames([]);
      if (failedFinding === true) {
        setFailedRows([]);
      }
    }
  }, [switchBehavior]);

  return (
    <>
    <TableRow
      key={row.id}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell component="th" scope="row">
        {index + 1}
      </TableCell>
      <TableCell component="th" scope="row">
        {row.controlname}
      </TableCell>
      <TableCell component="th" scope="row" width={"75%"}>
        {row.remediationdescription}
      </TableCell>
      {loading ? (
        <TableCell align="center" width={"100%"}>
            <Loader></Loader>
        </TableCell>
      ) : (
        <TableCell align="center" width={"100%"}>
          { accounttype === "AUTOMATED" ? 
            <>
            { process === "UPDATE" ?
              <Switch
                checked={rowSelection.includes(row) || row?.original?.remediatestatus === "ENABLED"} // Set checked state based on switchBehavior
                disabled={row?.original?.remediatestatus === "ENABLED" || activeSpinner}
                color="success"
                onClick={() => {
                  handleQuestionAnswer(row);
                }}
              />
              :
              <Switch
                checked={rowSelection.includes(row)} // Set checked state based on switchBehavior
                color="success"
                disabled={activeSpinner}
                onClick={() => {
                  handleQuestionAnswer(row);
                }}
              />
            }
            </>
          : 
            <Typography>
              N/A - Read Only
            </Typography>      
          }
        </TableCell>
      )}
      <TableCell width={"5%"}>
        <IconButton color="secondary" onClick={() => setOpen(!open)}>
          {open ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
        </IconButton>
      </TableCell>
    </TableRow>
    {loading ? (
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Loader></Loader>
          </Collapse>
        </TableCell>
      </TableRow>
    ) : (
      data?.getAWSControl?.findings?.map((finding: any) =>{
        if(finding.Compliance.Status === "FAILED"){
          failedFinding = true;
        }
        return(
          <TableRow key={finding?.Id?.split("/").reverse()[0]} sx={{ '& > *': { borderBottom: 'unset' } }}>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={2}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box sx={{ display: "flex", justifyContent: "center", borderBottom:'set' }}>
                  <ComplianceStatus
                    value={finding.Compliance.Status}
                  ></ComplianceStatus>
                </Box>
              </Collapse>
            </TableCell>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box sx={{ margin: 1, display: "block", borderBottom:'set' }}>
                  {finding?.Resources && finding?.Resources[0]?.Name !== "N/A" && (
                  <Typography variant="body1" align="left" gutterBottom>
                    Name:  {finding?.Resources ? finding?.Resources[0]?.Name : ""}
                  </Typography>
                  )}
                  <Typography variant="body1" align="left" gutterBottom>
                    Service ID: {finding?.Resources ? finding?.Resources[0]?.Id : ""}
                  </Typography>
                  <Typography variant="body1" align="left" gutterBottom>
                    Finding ID: {finding?.Id?.split("/").reverse()[0]}
                  </Typography>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>         
        );
      })
    )}
    </>
  );
}