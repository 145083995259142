import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useListSecurityControlsQuery } from "../../hooks/useListSecurityControlsQuery";
import { useEffect, useRef, useState } from "react";
import Loader from "../common/Loader";
import QuestionBestPractice from "./QuestionBestPractice";
import useSnackBars from "../../hooks/useSnackbar";
import { Row } from "@tanstack/react-table";
import Control from "../../models/Control";
import { useUpdateBestPracticeMutation } from "../../generated";
import { useCreateMilestone } from "../../hooks/useCreateMilestone";
import useAccount from "../../hooks/useAccount";
import HriCounter from "./HriCounter";
import WatMultiAutomatedRemediationDialog from "./WatMultiAutomatedRemediationDialog";
import ConnectedAccount from "../../models/ConnectedAccount";
import LoadingButton from "@mui/lab/LoadingButton";

interface RiskCount {
  hriCount: number;
  initialHriCount: number;
}

interface AnswerBestPracticeProps {
  arn: string;
  createdWorkloads: any;
  riskCounts: RiskCount;
  changeTab: (index: number) => void;
  setUnlockStep3: (index: boolean) => void;
  process: string;
  technicalReviewArn: string;
  connectedAccounts: Array<ConnectedAccount>
}

function createData(
  controlname: string,
  remediationsteps: string,
  status: string,
  updateStatus: string,
  reducedHri: number,
  original: Row<Control>[]
) {
  let remediationdescription: any = "";
  if (remediationsteps !== "") {
    let parsedData = JSON.parse(remediationsteps);
    remediationdescription = parsedData.description;
  }
  return {
    controlname,
    remediationdescription,
    status,
    updateStatus,
    reducedHri,
    original,
  };
}

type RowQuestion = {
  controlname: string;
  remediationsteps: string;
  status: string;
  updateStatus: string;
  reducedHri: number;
};

export default function AnswerBestPractice({
  arn,
  createdWorkloads,
  riskCounts,
  changeTab,
  setUnlockStep3,
  process,
  technicalReviewArn,
  connectedAccounts,
}: AnswerBestPracticeProps) {
  const { accounts } = useAccount();
  const account = accounts.find((i: any) => i.arn === technicalReviewArn);

  const { controls, loading, updateData } = useListSecurityControlsQuery({
    arn: technicalReviewArn,
    connectedAccounts: connectedAccounts,
  });

  const { createMilestone } = useCreateMilestone({
    arn,
  });

  const [updateBestPracticeMutation] = useUpdateBestPracticeMutation();
  const { setAlerts } = useSnackBars();

  const [rows, setRows] = useState<Array<RowQuestion>>([]);
  const [rowSelection, setRowSelection] = useState([]);
  const [rowNames, setRowNames] = useState([]);
  const [failedRows, setFailedRows] = useState<string[]>([]);
  const [noRemediation, setNoRemediation] = useState(true);
  const [open, setOpen] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [activeSpinner, setActiveSpinner] = useState(false);
  const [switchBehavior, setSwitchBehavior] = useState("Off");
  const [accountIdRef, setAccountIdRef] = useState<string>("");
  const scrollRef = useRef<null | HTMLDivElement>(null);

  const handleClickOpen = () => {
    setOpen(true);
    setActiveSpinner(true);
  };

  const handleClose = () => {
    setOpen(false);
    setDisabled(false);
    setActiveSpinner(false);
  };

  const handleConfirm = async (
    status: boolean,
    controls: string[],
    msg: string
  ) => {
    if (status) {
      setOpen(false); //Closes the Multi Automated Remediation Modal after its initiated all the remediations
      updateData(controls, "remediatestatus", "ENABLED"); //this is done to ensure that when a milestone is created below it gets the latest controls compliance status
      let milestonename = "Best-Practice Recommendations";
      //Create the best-practice milestone
      const promises = Object.entries(createdWorkloads).map(
        async ([key, value]) => {
          const updatebestpractice = await updateBestPracticeMutation({
            variables: {
              arn: arn, // value for 'arn'
              workloadid: typeof value === "string" ? value : "", // value for 'workloadid'
              lens: "wellarchitected", // value for 'lens'
              controls: rowNames, // value for 'controls'
            },
            context: {
              apiName: "well_architected",
            },
          });
          if (
            updatebestpractice?.data?.updateBestPractice?.status === "COMPLETE"
          ) {
            let milestone_promise = await Promise.all([
              createMilestone(
                typeof value === "string" ? value : "",
                milestonename
              ),
            ]);
            return milestone_promise;
          }
        }
      );
      await Promise.all(promises);
      setAlerts([
        {
          severity: "success",
          msg: "Best-practice Recommendations Successfully Enabled.",
        },
      ]);
      //switch to step 3
      setUnlockStep3(true);
      changeTab(2);
    } else if (msg !== "") {
      setAlerts([
        {
          severity: "error",
          msg: msg,
        },
      ]);
    } else {
      setAlerts([
        {
          severity: "error",
          msg: `Best-practice Recommendations toggling failed for following controls ${controls.join(
            ", "
          )}`,
        },
      ]);
    }
    setOpen(false);
    setDisabled(false);
  };

  //If AccountType is Read_Only or no controls are selected to be remediated move to Step 3 when Next is clicked
  //Otherwise open Multi Automated Remediation Dialog
  const handleSubmit = () => {
    if (account?.accounttype !== "AUTOMATED") {
      setUnlockStep3(true);
      changeTab(2);
    } else {
      if (noRemediation === true) {
        setUnlockStep3(true);
        changeTab(2);
      } else {
        setDisabled(true);
        handleClickOpen();
      }
    }
  };

  useEffect(() => {
    //Controls in here are those that have the well-architected filter but aren't in the excel file that has wat best-practice mappings
    const donotdisplaycontrols: Array<string> = [
      "CloudTrail.1",
      "CloudTrail.4",
      "CloudTrail.7",
      "CloudWatch.3",
      "CloudWatch.4",
      "CloudWatch.5",
      "CloudWatch.6",
      "CloudWatch.7",
      "Config.1",
      "EC2.1",
      "EC2.6",
      "RDS.1",
      "RDS.6",
      "RDS.8",
      "S3.9",
    ];

    //Remove this if statement after we have fixed being able to remediate the below controls in a sub account
    if (
      technicalReviewArn.split(":")[4] !== connectedAccounts?.[0]?.accountIdRef 
      && connectedAccounts.length > 0
    ) {
      donotdisplaycontrols.push(
        "Macie.1",
        "GuardDuty.1",
        "CloudWatch.1",
        "CloudWatch.2",
        "CloudWatch.8",
        "CloudWatch.9",
        "CloudWatch.10",
        "CloudWatch.11",
        "CloudWatch.12",
        "CloudWatch.13",
        "CloudWatch.14"
      );
    }

    let data: any = controls.filter((control: any) => {
      if (
        Array.isArray(control?.customStandard) &&
        !donotdisplaycontrols.includes(control?.controlname) &&
        control?.intrusive === "NON-INTRUSIVE" &&
        control?.customStandard.length > 0 &&
        control?.customStandard.some((standard: string) => {
          return standard.includes("AWS Well Architected Framework");
        })
      ) {
        return true;
      }
      return false;
    });
    if (!loading && data) {
      setRows(
        data?.map((q: any) => {
          return createData(
            q.controlname,
            q.remediationsteps,
            "",
            "INITIAL",
            0,
            q
          );
        })
      );
    }
  }, [loading]);

  useEffect(() => {
    //If Controls are selected to be remediated then setNoRemediation to false 
    //so that Multi Automated Remediation Modal opens
    if (rowNames.length > 0) {
      setNoRemediation(false);
    } else {
      setNoRemediation(true);
    }
  }, [rowNames]);

  useEffect(() => {
    if (account?.accounttype !== "AUTOMATED" && rows.length !== 0) {
      scrollRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [account, rows]);

  const handleSwitch = () => {
    setSwitchBehavior((prev) => (prev === "Off" ? "All" : "Off")); // Toggle between "All" and "Independent"
  };

  useEffect(() => {
    if (connectedAccounts.length > 0) {
      setAccountIdRef(connectedAccounts?.[0]?.accountIdRef);
    } else {
      setAccountIdRef(technicalReviewArn.split(":")[4]);
    }
  }, [connectedAccounts]);
  return (
    <Container maxWidth="lg" component={Paper}>
      <Grid container sx={{ p: 1 }}>
        <Typography>
          NB: You will need to answer all questions in the one sitting due to
          the way that the workload is updated. If you would like any
          assistance, please reach out to secure@6pillars.ai
        </Typography>
        <Grid item xs md={12} sx={{ pt: 2 }}>
          <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
            <Typography fontWeight="bold" noWrap>
              Select All Recommendations?
            </Typography>
            <Typography fontWeight="bold" noWrap>
              &nbsp;
            </Typography>
            <Switch
              checked={switchBehavior === "All"} // Set checked state based on switchBehavior
              disabled={rows.length === 0 || activeSpinner}
              onChange={handleSwitch} // Handle switch toggling
              color="success"
            />
          </Box>
        </Grid>
        <Grid item xs md={12}>
          <HriCounter
            totalReducedHri={riskCounts.hriCount}
            initialHriCount={riskCounts.initialHriCount}
            questionnairetab={false}
          ></HriCounter>
        </Grid>
      </Grid>
      <Grid item xs md={4}></Grid>
      <Grid
        container
        sx={{ pb: 2, pt: 2, pl: 2 }}
        spacing={2}
        alignItems="center"
      >
        <TableContainer component={Paper}>
          <Paper
            key={"3"}
            component="div"
            elevation={0}
            sx={{
              p: "2px 4px",
              mb: 2,
              display: "flex",
              alignItems: "right",
              width: 205,
              justifyContent: "center",
            }}
          >
            {disabled ? (
              <WatMultiAutomatedRemediationDialog
                open={open}
                handleClose={handleClose}
                handleConfirm={handleConfirm}
                rows={rowSelection}
                failedRows={failedRows}
                technicalReviewArn={technicalReviewArn}
                accountRefId={accountIdRef}
              ></WatMultiAutomatedRemediationDialog>
            ) : null}
          </Paper>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Control ID</TableCell>
                <TableCell>Select Best-Practice Recommendations?</TableCell>
                <TableCell align="center">Select</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length === 0 && (
                <TableRow
                  key={1}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell colSpan={3}>
                    <Loader></Loader>
                  </TableCell>
                </TableRow>
              )}
              {rows.map((row, index) => (
                <QuestionBestPractice
                  key={index}
                  technicalReviewArn={technicalReviewArn}
                  index={index}
                  accounttype={account?.accounttype || "READ_ONLY"}
                  rows={rows}
                  row={row}
                  rowSelection={rowSelection}
                  setRowSelection={setRowSelection}
                  rowNames={rowNames}
                  setRowNames={setRowNames}
                  failedRows={failedRows}
                  setFailedRows={setFailedRows}
                  switchBehavior={switchBehavior}
                  process={process}
                  connectedAccounts={connectedAccounts}
                  activeSpinner={activeSpinner}
                ></QuestionBestPractice>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid item md={10}></Grid>
        <Grid item md={2}>
          <Box ref={scrollRef}>
            <LoadingButton
              variant="contained"
              color="secondary"
              type="submit"
              disabled={disabled}
              loading={activeSpinner}
              onClick={handleSubmit}
              sx={{
                width: 157,
                height: 40,
                bgcolor: "secondary.main",
                "&:hover": {
                  bgcolor: "secondary.main",
                  color: "secondary.contrastText",
                },
              }}
              loadingIndicator={
                <CircularProgress color={"secondary"} size={22} />
              }
              autoFocus
            >
              Next
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
