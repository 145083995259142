import { FC, useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { useGetUrlQuery } from "../../generated";
import LoadingButton from "@mui/lab/LoadingButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const SixPillarsRoleButton: FC<any> = ({
  launch,
  update,
  open,
  uuid,
  accountRegion,
  template,
}: {
  launch: boolean;
  update: boolean;
  open: boolean;
  template: string;
  uuid: string;
  accountRegion: string;
}) => {
  const { data, loading, refetch } = useGetUrlQuery({
    variables: {
      template: template, // value for 'template'
    },
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    refetch();
  }, []);

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      {launch && (
        <LoadingButton
          variant="contained"
          color="secondary"
          disabled={loading}
          size="small"
          sx={{ m: 2 }}
          loading={loading}
          onClick={() => {
            let launchurl = `https://console.aws.amazon.com/cloudformation/home?region=${accountRegion}#/stacks/create/review?stackName=six-pillars&templateURL=${encodeURIComponent(
              data?.getTemplateS3URL
            )}&param_ExternalId=${uuid}&EnableSixPillarsDeployAccess=YES`;
            window.open(launchurl);
          }}
          endIcon={<OpenInNewIcon />}
          loadingPosition="end"
          autoFocus
        >
          Launch Create Stack
        </LoadingButton>
      )}
      {update && (
        <LoadingButton
          variant="contained"
          color="secondary"
          size="small"
          sx={{ m: 2 }}
          disabled={loading}
          onClick={() => {
            window.open("https://console.aws.amazon.com/cloudformation/home");
          }}
          endIcon={<OpenInNewIcon />}
          loadingPosition="end"
        >
          Open CloudFormation
        </LoadingButton>
      )}
      {open && (
        <LoadingButton
          variant="contained"
          color="secondary"
          disabled={loading}
          size="small"
          loading={loading}
          sx={{ m: 2 }}
          onClick={() => {
            navigator.clipboard.writeText(data?.getTemplateS3URL);
          }}
          autoFocus
          endIcon={<ContentCopyIcon />}
          loadingPosition="end"
        >
          Role stack URL
        </LoadingButton>
      )}
    </Box>
  );
};

export default SixPillarsRoleButton;
