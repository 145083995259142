import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Paper,
  InputAdornment,
  InputLabel,
  FormControl,
  Grid,
  MenuItem,
  SelectChangeEvent,
  OutlinedInput,
  Pagination,
  Box,
  IconButton,
  TableCell,
  Collapse,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import DeleteAccount from "./DeleteAccount";
import RollbackAccount from "./rollback-account/RollbackAccount";
import TestAccount from "./TestAccount";
import StyledSelect from "../common/StyledSelect";
import StyledTableCell from "../common/StyledTableCell";
import Loader from "../common/Loader";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import UpgradeAccount from "./UpgradeAccount";
import AccountProgress from "./AccountProgress";
import { client } from "../../lib/clients/apolloClient";
import { AutomateProgressStatusDocument } from "../../generated";
import ControlStatusesDownloadBtn from "./ControlStatusesDownloadBtn";
import useRegions from "../../hooks/useRegions";
import LaunchSixPillarsTemplate from "./LaunchSixPillarsTemplate";

interface ManageAccountsProps {
  setTabSwitch: any;
  tabSwitch: any;
  data: any;
  loading: boolean;
  refetch: any;
}
interface Data {
  organisation: string;
  accessnickname: string;
  arn: string;
  defaultregion: string;
  accounttype: string;
  externalid: string;
  regionlabel: any;
  sharrversion: string;
}

interface ExpandableRowInterface {
  item: any;
  setTableUpdateData: any;
  setAutomateProgress: any;
  accountDeleted: any;
  setAccountDeleted: any;
  setTabSwitch: any;
  tabSwitch: any;
}

//Each row on the table
function ExpandableRow({
  item,
  setTableUpdateData,
  setAutomateProgress,
  accountDeleted,
  setAccountDeleted,
  setTabSwitch,
  tabSwitch,
}: ExpandableRowInterface) {
  const [open, setOpen] = useState(false);
  const [disableButton, setdisableButton] = useState(false);
  const [progressStatus, setProgressStatus] = useState("");
  const [createdDate, setCreatedDate] = useState("");
  const [rollbackOngoing, setRollbackOngoing] = useState(false);

  let sharrAppVersion = item.sharrversion || "N/A";
  return (
    <>
      <TableRow key={item.arn}>
        <StyledTableCell>{item.accessnickname}</StyledTableCell>
        <StyledTableCell>
          <AccountProgress
            arn={item.arn}
            organisation={item.organisation}
            progressStatus={progressStatus}
            setProgressStatus={setProgressStatus}
            setTableUpdateData={setTableUpdateData}
            accountDeleted={accountDeleted}
            setAccountDeleted={setAccountDeleted}
            setdisableButton={setdisableButton}
            setCreatedDate={setCreatedDate}
            setTabSwitch={setTabSwitch}
            tabSwitch={tabSwitch}
            setRollbackOngoing={setRollbackOngoing}
          />
        </StyledTableCell>
        <StyledTableCell>
          <TestAccount
            arn={item.arn}
            disableButton={disableButton}
            accountDeleted={accountDeleted}
          />
        </StyledTableCell>
        <StyledTableCell>
          <RollbackAccount
            organisation={item.organisation}
            arn={item.arn}
            accessnickname={item.accessnickname}
            externalId={item.externalid}
            defaultregion={item.defaultregion}
            setProgressStatus={setProgressStatus}
            setAutomateProgress={setAutomateProgress}
            setAccountDeleted={setAccountDeleted}
            rollbackOngoing={rollbackOngoing}
          />
        </StyledTableCell>
        <StyledTableCell>
          <UpgradeAccount
            organisation={item.organisation}
            arn={item.arn}
            accessnickname={item.accessnickname}
            externalId={item.externalid}
            defaultregion={item.defaultregion}
            disableButton={disableButton}
            setProgressStatus={setProgressStatus}
            setAutomateProgress={setAutomateProgress}
          />
        </StyledTableCell>
        <StyledTableCell>
          <IconButton color="secondary" onClick={() => setOpen(!open)}>
            {open ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
          </IconButton>
        </StyledTableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1, display: "block" }}>
              <Typography variant="body1" align="left" gutterBottom>
                <b>Role ARN:</b> {item.arn || "N/A"}
              </Typography>
              <Typography variant="body1" align="left" gutterBottom>
                <b>Account Type:</b> {item.accounttype || "N/A"}
              </Typography>
              <Typography variant="body1" align="left" gutterBottom>
                <b>Created Date:</b> {createdDate}
              </Typography>
              <Typography variant="body1" align="left" gutterBottom>
                <b>External ID:</b> {item.externalid || "N/A"}
              </Typography>
              <Typography variant="body1" align="left" gutterBottom>
                <b>Region:</b> {item.regionlabel || "N/A"}
              </Typography>
              <Typography variant="body1" align="left" gutterBottom>
                <b>Version:</b> {sharrAppVersion}
              </Typography>
              <Grid container alignItems="center">
                <Grid item md={3} sx={{mr: 1}}>
                  <DeleteAccount
                    arn={item.arn}
                    setTableUpdateData={setTableUpdateData}
                  />
                </Grid>
                <Grid item md={3} sx={{mr: 2}}>
                  <LaunchSixPillarsTemplate 
                    accountType={item.accounttype}
                    defaultregion={item.defaultregion}
                    externalId={item.externalid}                  
                  />
                </Grid>
                <Grid item md={3}>
                  {sharrAppVersion === "2.0.6.1505" ? (
                    <ControlStatusesDownloadBtn
                      arn={item.arn}
                    ></ControlStatusesDownloadBtn>
                  ) : (
                    <></>
                  )}
                </Grid>
                <Grid item md={3}></Grid>
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default function ManageAccounts({
  setTabSwitch,
  tabSwitch,
  data,
  loading,
  refetch,
}: ManageAccountsProps) {
  const [tableUpdateData, setTableUpdateData] = useState(false);
  const [automateProgress, setAutomateProgress] = useState(false);
  const [accountDeleted, setAccountDeleted] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const regions = useRegions();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState<Data[]>([]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  //Set the Data
  const transformdata = (): Data[] => {
    return (
      data?.getUserAccounts?.items.map((getUserAccounts: any): Data => {
        let userregion: string =
          regions.get(getUserAccounts?.defaultregion) || "";
        let account_type = "";
        if (getUserAccounts?.accounttype === "AUTOMATED") {
          account_type = "AUTOMATE+";
        } else if (getUserAccounts?.accounttype === "READ_ONLY") {
          account_type = "AUTOMATE (Read-Only)";
        }

        const data: Data = {
          organisation: getUserAccounts.organisation,
          accessnickname: getUserAccounts.accessnickname,
          arn: getUserAccounts.arn,
          defaultregion: getUserAccounts?.defaultregion,
          accounttype: account_type,
          externalid: getUserAccounts.externalid,
          regionlabel: userregion,
          sharrversion: getUserAccounts?.sharrversion,
        };

        return data;
      }) || []
    );
  };

  //This is called when an account is deleted and Filtered data needs to be reset
  const transformNewData = (newData: any): Data[] => {
    return (
      newData?.data?.getUserAccounts?.items.map(
        (getUserAccounts: any): Data => {
          let userregion: string =
            regions.get(getUserAccounts?.defaultregion) || "";
          let account_type = "";
          if (getUserAccounts?.accounttype === "AUTOMATED") {
            account_type = "AUTOMATE+";
          } else if (getUserAccounts?.accounttype === "READ_ONLY") {
            account_type = "AUTOMATE (Read-Only)";
          }

          const data: Data = {
            organisation: getUserAccounts.organisation,
            accessnickname: getUserAccounts.accessnickname,
            arn: getUserAccounts.arn,
            defaultregion: getUserAccounts?.defaultregion,
            accounttype: account_type,
            externalid: getUserAccounts.externalid,
            regionlabel: userregion,
            sharrversion: getUserAccounts?.sharrversion,
          };

          return data;
        }
      ) || []
    );
  };

  //Use effect to load accounts and also trigger automate progress account query when a new account is dpeloyed
  useEffect(() => {
    if (!loading && data?.getUserAccounts?.items) {
      const accountsData: Data[] = transformdata();
      setFilteredData(accountsData);
    }
  }, [data, loading]);

  //Use effect for search box
  useEffect(() => {
    if (searchTerm && searchTerm !== "")
      setFilteredData(
        transformdata().filter((d) =>
          Object.values(d).some((value) =>
            value.toString().toLowerCase().includes(searchTerm.toLowerCase())
          )
        )
      );
  }, [searchTerm]);

  //Use effect to update table if data changes like account gets deleted
  useEffect(() => {
    if (tableUpdateData) {
      refetch().then((newpulldata: any) => {
        const refetchedAccountsData: Data[] = transformNewData(newpulldata);
        setFilteredData(refetchedAccountsData);
        setTableUpdateData(false);
      });
    }
  }, [tableUpdateData]);

  //Use effect to trigger automate progress query if rollback or upgrade button is clicked
  useEffect(() => {
    if (automateProgress) {
      client.refetchQueries({
        include: [AutomateProgressStatusDocument],
      });
      setAutomateProgress(false);
    }
  }, [automateProgress]);

  return (
    <Container>
      <Box sx={{ pt: 4 }}>
        <Container maxWidth="lg" sx={{ width: 1 }} component={Paper}>
          <Grid container flexDirection="row">
            <Typography
              variant="h4"
              align="left"
              color="primary.main"
              gutterBottom
              sx={{ mt: "32px" }}
            >
              Connected Accounts
            </Typography>
          </Grid>
          <Grid container flexDirection="row" sx={{ ml: 0 }}>
            <Paper
              component="form"
              sx={{
                p: "0px 0px",
                mb: 0,
                mt: "2px",
                display: "flex",
                border: 0,
                boxShadow: 0,
              }}
            >
              <FormControl
                sx={{ m: 1, ml: 0, width: "305px", alignSelf: "center" }}
                variant="outlined"
                size="small"
              >
                <InputLabel htmlFor="component-outlined">Search</InputLabel>
                <OutlinedInput
                  label="Search"
                  id="component-outlined"
                  type={"text"}
                  value={searchTerm}
                  onChange={handleSearch}
                  endAdornment={
                    <InputAdornment position="end">
                      <SearchIcon sx={{ color: "secondary.main" }} />
                    </InputAdornment>
                  }
                  placeholder="Search"
                  inputProps={{ "aria-label": "Search" }}
                />
              </FormControl>
            </Paper>
            <Paper
              component="form"
              elevation={0}
              sx={{
                p: "2px 4px",
                mb: 2,
                display: "flex",
                justifyContent: "flex-end",
                flexGrow: 1,
              }}
            >
              <InputLabel id="page-size" sx={{ alignSelf: "center", p: 1 }}>
                Show
              </InputLabel>
              <FormControl sx={{ m: 1, minWidth: 119 }} size="small">
                <StyledSelect
                  labelId="page-size-select-label"
                  id="page-size-select"
                  defaultValue={rowsPerPage}
                  onChange={(e: SelectChangeEvent<unknown>) => {
                    setRowsPerPage(Number(e.target.value));
                    setPage(0);
                  }}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </StyledSelect>
              </FormControl>
            </Paper>
          </Grid>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Account</StyledTableCell>
                  <StyledTableCell sx={{ width: "30%" }}>
                    Deployment Status
                  </StyledTableCell>
                  <StyledTableCell sx={{ width: "5%" }}>
                    Access
                  </StyledTableCell>
                  <StyledTableCell sx={{ width: "10%" }}>
                    Rollback
                  </StyledTableCell>
                  <StyledTableCell sx={{ width: "10%" }}>
                    Upgrade
                  </StyledTableCell>
                  <StyledTableCell sx={{ width: "5%" }}></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <StyledTableCell
                      colSpan={12}
                      sx={{ justifyContent: "center" }}
                    >
                      <Loader></Loader>
                    </StyledTableCell>
                  </TableRow>
                ) : (
                  filteredData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((getUserAccounts: any, index: number) => {
                      return (
                        <>
                          <ExpandableRow
                            key={index}
                            item={getUserAccounts}
                            setTableUpdateData={setTableUpdateData}
                            setAutomateProgress={setAutomateProgress}
                            accountDeleted={accountDeleted}
                            setAccountDeleted={setAccountDeleted}
                            setTabSwitch={setTabSwitch}
                            tabSwitch={tabSwitch}
                          />
                        </>
                      );
                    })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid
            container
            flexDirection="row"
            justifyContent={"flex-end"}
            sx={{ p: 0, pt: 2, pb: 2 }}
          >
            <Pagination
              count={
                data?.getUserAccounts?.items.length
                  ? Math.ceil(data?.getUserAccounts?.items.length / rowsPerPage)
                  : 0
              }
              variant="outlined"
              sx={{
                color: "primary.main",
                borderColor: "primary.main",
              }}
              shape="rounded"
              page={page + 1}
              onChange={(event: React.ChangeEvent<unknown>, value: number) => {
                setPage(value - 1);
              }}
            />
          </Grid>
        </Container>
      </Box>
    </Container>
  );
}
