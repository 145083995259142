import Typography from '@mui/material/Typography';
import { FC } from 'react';
import { useListMilestonesQuery } from "../../generated";

interface ListMilestonesProps {
  arn: string;
  workloadid: string;
}

const ListMilestones: FC<ListMilestonesProps> = ({workloadid, arn}: ListMilestonesProps) => {
  const milestones = useListMilestonesQuery({
    variables: {
      arn: arn,
      workloadid: workloadid
    },
    context: {
      apiName: "well_architected"
    },
    notifyOnNetworkStatusChange: true,
  });
  let milestonenumber = 0;
  
  milestones.data?.listMilestones?.MilestoneSummaries.map((value: any, index) => {
    if(index < 1){
      milestonenumber = value.MilestoneNumber;
    }
    return (
      <></>
    );
  })

  return (
    <Typography component={"span"} variant="body1" color="text.secondary" paragraph>{milestonenumber}</Typography>
  );
}

export default ListMilestones;