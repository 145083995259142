import { useCreateWorkloadMutation } from "../generated";
import ConnectedAccount from "../models/ConnectedAccount";
import { useCreateMilestone } from "./useCreateMilestone";
import { useUpdateWorkload } from "./useUpdateWorkload";

interface CreateWorkloadInput {
    arn: string;
    region: string;
}

const useCreateWorkload = ({
    arn, region
}: CreateWorkloadInput) => {
    const [createWorkloadMutation] = useCreateWorkloadMutation();

    const { updateWorkload } = useUpdateWorkload(
        {
          arn
        }
    );

    const { createMilestone } = useCreateMilestone(
        {
          arn
        }
    );

    const createWorkload = async (workloadname: string, target_arn: string, connectedAccounts: Array<ConnectedAccount>, accountIdRef: string) => {
        let account_assessed_id = target_arn.split(":")[4];
        if(accountIdRef !== ""){
            account_assessed_id = accountIdRef;
        }
        const workloadcreation = await createWorkloadMutation({
            variables: {
              arn: arn,
              workloadname: workloadname,
              description: "Mapping Security Hub with Well-Architected Tool",
              environment: "PRODUCTION",
              accountAssessedId: account_assessed_id,
              awsregions: region,
              reviewowner: "admin",
              lenses: "wellarchitected",
              notes: "",
            },
            context: {
              apiName: "well_architected",
            },
        });
        if(workloadcreation?.data?.createWorkload?.Status === "SUCCESS"){
            const updateanswer = await Promise.all([
                updateWorkload(workloadcreation?.data?.createWorkload?.WorkloadId || "", target_arn, connectedAccounts),
            ]);
            const createmilestone = await Promise.all([
                createMilestone(
                    workloadcreation?.data?.createWorkload?.WorkloadId || "",
                    workloadname
                ),
            ]);       
            let highriskcount = createmilestone[0]?.data?.createMilestone?.RiskCounts?.HIGH;   
            return {workloadcreation, updateanswer, highriskcount}
        } 
        return {workloadcreation}
    };
    return {createWorkload};
};

export { useCreateWorkload };