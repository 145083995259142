import { Typography, Box } from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";

const ComplianceStatus = ({ value, ...rest }: { value: string }) => {
  let color = "#383737";
  let icon = <></>;
  if (value === "PASSED") {
    color = "success.main";
    icon = (
      <CheckCircleOutlineRoundedIcon
        sx={{ color }}
      ></CheckCircleOutlineRoundedIcon>
    );
  } else if (value === "FAILED") {
    color = "error.main";
    icon = <CancelOutlinedIcon sx={{ color }}></CancelOutlinedIcon>;
  }
  return (
    <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
      {icon}
      <Typography fontSize={"14px"} color={color} sx={{ ml: 0.5 }}>
        {value}
      </Typography>
    </Box>
  );
};

export default ComplianceStatus;
