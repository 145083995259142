import { FC, useState } from 'react';
import {
    Container,
    Typography,
    Paper,
    Grid,
} from '@mui/material';
import RollbackAccount from './RollbackAccount';
import RegionDropdown from './RegionDropdown';

const AccountRollback: FC = () => {

    const [accountRegion, setAccountRegion] = useState('ap-southeast-2');

    return (
      <Container sx={{ width: 1 }} component={Paper}>
        <Grid container spacing={2} alignItems="center">
            <Grid item md={12}>
                <Typography
                    variant="h4"
                    color="primary.main"
                    gutterBottom
                >
                Remove AUTOMATE+ from Account
                </Typography>
            </Grid>
            <Grid item md={12}>
                <Typography variant="body2">
                    By clicking the Launch Rollback button you will launch a CloudFormation template which will remove AUTOMATE+ from your AWS Account.
                </Typography>
            </Grid>
            <Grid item md={4}>
                <RegionDropdown accountRegion={accountRegion} setAccountRegion={setAccountRegion} />
            </Grid>
            <Grid item md={8}></Grid>
            <Grid item md={4}>
                <RollbackAccount defaultregion={accountRegion}/>
            </Grid>
            <Grid item md={8}></Grid>
            <Grid item md={12}></Grid>
        </Grid>
      </Container>
    )
}

export default AccountRollback;