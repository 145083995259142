import { useState } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import { GetUrlDocument } from "../../generated";
import { Button } from "@mui/material";

interface LaunchSixPillarsTemplateProps {
  accountType: string;
  defaultregion: string;
  externalId: string;
}

export default function LaunchSixPillarsTemplate({
  accountType,
  defaultregion,
  externalId,
}: LaunchSixPillarsTemplateProps) {
  const [disable, setDisable] = useState(false);
  //Get CFN Template for six pillars stack and open it in new tab window
  const [getRoleTemplateURL] = useLazyQuery(GetUrlDocument);

  //Launch CFN Template for six pillars stack and open it in new tab window
  const launchCfnTemplate = (uuid: string, roleTemplateURLData: any) => {
    let launchurl = `https://console.aws.amazon.com/cloudformation/home?region=${defaultregion}#/stacks/create/review?stackName=six-pillars&templateURL=${encodeURIComponent(
      roleTemplateURLData?.data?.getTemplateS3URL
    )}&param_ExternalId=${uuid}`;
    setDisable(true);
    window.open(launchurl);
  };

  //Get correct CFN Template for six pillars stack based on account type
  const getRoleTemplateURLPromise = () => {
    let templateType = "";
    if (accountType === "AUTOMATE+") {
      templateType = "roles/SixPillarRoleStack.template.json";
    } else {
      templateType = "roles/SixPillarReadOnlyRoleStack.template.json";
    }

    return getRoleTemplateURL({
      variables: {
        template: templateType, // value for 'template'
      },
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
    });
  };

  //Initiate CFN Stack Role Deployment
  const deployRole = async () => {
    let uuid = externalId;
    const responses = await Promise.all([
      getRoleTemplateURLPromise(),
    ]);
    launchCfnTemplate(uuid, responses[0]);
    setDisable(false);
  };

  return ( 
    <Button
      disabled={disable}
      onClick={() => deployRole()}
      type="submit"
      variant="contained"
      sx={{
      width: 250,
      height: 40,
      bgcolor: "secondary.main",
      "&:hover": {
          bgcolor: "secondary.main",
          color: "secondary.contrastText",
      },
      }}
    >
      Launch Automate/+
    </Button>
  );
}
