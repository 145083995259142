import { SelectChangeEvent, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useState } from 'react';
import useRegions from "../../hooks/useRegions";

interface RollbackAccountProps { 
    accountRegion: any;
    setAccountRegion: any;
}

export default function RegionDropdown({accountRegion, setAccountRegion}: RollbackAccountProps) {   
    
    const [region, setRegion] = useState(accountRegion);

    const regions = useRegions();
    
    const handleRegion = (event: SelectChangeEvent) => {
        setRegion(event.target.value as string);
        setAccountRegion(event.target.value as string);
    };

    
    return (
        <FormControl fullWidth>
            <InputLabel id="dep_arn">Region</InputLabel>
            <Select
                id="dep_region"
                value={region}
                label="Region"
                onChange={handleRegion}
            >
                {Array.from(regions.keys()).map((region: any) => {
                    return (
                        <MenuItem key={region} value={region}>
                        {regions.get(region)}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
}