import Stack from "@mui/material/Stack";
import { GetUrlDocument } from "../../generated";
import Button from "@mui/material/Button";
import { useLazyQuery } from "@apollo/react-hooks";
import { useState } from "react";
import { CircularProgress } from "@mui/material";

interface RollbackAccountProps {
  defaultregion: string;
}

export default function RollbackAccount({
  defaultregion,
}: RollbackAccountProps) {
  const [loading, setLoading] = useState(false);
  const [getRoleTemplateURL] = useLazyQuery(GetUrlDocument, {
    onCompleted: (RoleTemplateURLData) => {
      let launchurl = `https://console.aws.amazon.com/cloudformation/home?region=${defaultregion}#/stacks/create/review?stackName=six-pillars-rollback&templateURL=${encodeURIComponent(
        RoleTemplateURLData?.getTemplateS3URL
      )}`;
      setTimeout(() => window.open(launchurl), 1000);
      setLoading(false);
    },
  });

  function Buttonclick() {
    setLoading(true);
    getRoleTemplateURL({
      variables: {
        template: "templates/SixPillarRollbackStack.template.json", // value for 'template'
      },
      fetchPolicy: "no-cache",
    });
  }

  return (
    <Stack direction="row">
      <Button
        disabled={loading}
        onClick={Buttonclick}
        id="automate-setup-btn"
        type="submit"
        variant="contained"
        endIcon={loading ? <CircularProgress /> : ""}
        sx={{
          width: 257,
          height: 40,
          bgcolor: "secondary.main",
          "&:hover": {
            bgcolor: "secondary.main",
            color: "secondary.contrastText",
          },
        }}
      >
        Launch Rollback
      </Button>
    </Stack>
  );
}
