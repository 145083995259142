import React, {useState, useEffect} from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Button,
  Box,
  CircularProgress,
  styled,
  Stack,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useGetBestPracticeResponse from "../../hooks/useGetBestPracticeResponse";

interface BestPracticeModalProps {
  open: boolean;
  checkType: string;
  resource: string;
  description: string;
  checkId: string;
  onClose: () => void;
}

interface NewLineToBrProps {
    text: string;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
}));

const NewLineToBr: React.FC<NewLineToBrProps> = ({ text }) => {
    // Split the input text based on new lines and process each line
    const content = text.split('\n').map((line, index) => (
        <span key={index} style={{ whiteSpace: 'pre-wrap' }}>
            {line}
            <br />
        </span>
    ));
    return <>{content}</>;
};

const getAwsServiceFromResource = (checkType: string, resource: string) => {
    if (checkType === "cloudformation" && resource.startsWith("AWS::")) {
        const serviceName = resource.split("::")[1]
        return serviceName;
    }else if (checkType === "terraform" && resource.startsWith("aws_")) {
        const serviceName = resource.split("_")[1]
        return serviceName.toUpperCase();
    }else {
        return "Best Practice";
    }
}

const BestPracticeModal: React.FC<BestPracticeModalProps> = ({
  open,
  checkType,
  checkId,
  resource,
  description,
  onClose,
}) => {
    const [bestPracticeCode, setBestPracticeCode] = useState("");
    const [loading, setLoading] = useState(false);
    const [calledOnce, setCalledOnce] = useState(false);
    const {getBestPractice, refetch} = useGetBestPracticeResponse({
        checkType,
        checkId,
        resource,
        description,
        setLoading,
        setBestPracticeCode,
    })

    useEffect(() => {
        if (open) {
            setLoading(true);
            getBestPractice();
            if (!calledOnce) {
                setCalledOnce(true);
            }else {
                refetch();
            }
        }
    }, [open])

    return (
        <React.Fragment>
            <BootstrapDialog
                onClose={onClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    {getAwsServiceFromResource(checkType, resource)} Policy Template
                </DialogTitle>
                <DialogContent>
                    <Typography gutterBottom>
                        The code snippets provided by pressing "Generate Remediation Template", 
                        are a guided suggestion leveraging AI and require testing by you or your team 
                        prior to adding to your IaC templates.
                    </Typography>
                    <Typography gutterBottom fontWeight="bold">
                        This may take up to 30 seconds
                    </Typography>
                </DialogContent>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    {loading ? 
                        <Stack alignItems="center">
                            <CircularProgress />
                        </Stack>
                    :                
                        <Box
                            sx={{
                                height: "400px",
                                border: "1px solid grey",
                                padding: "4px",
                                overflow: "auto",
                            }}
                        >
                            <Typography style={{wordWrap: "break-word"}}>
                                <NewLineToBr text={bestPracticeCode} />
                            </Typography>
                        </Box>
                    }
                </DialogContent>
                <DialogActions>
                    <DialogContent>
                        <Typography gutterBottom>
                            By pressing "CLOSE" the generation request will be cancelled. 
                            However you can restart the request at a later time if required, 
                            and perform the request multiple times if needed.
                        </Typography>
                    </DialogContent>
                    <Button variant="contained" autoFocus onClick={onClose} color="secondary">
                        Close
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
};

export default BestPracticeModal;
