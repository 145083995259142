import React, { useState, useEffect } from "react";

import {
  useGetTemplateEngineSecurityResponseLazyQuery,
  useGetTemplateEngineSecurityResponseQuery,
} from "../../generated";

import useGetTscanRules from "../../hooks/useGetTscanRules";

import {
  IconButton,
  Paper,
  Box,
  Collapse,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Table,
  Container,
  styled,
  Stack,
  TextField,
  InputAdornment,
  Grid,
  Typography,
  Button,
  InputLabel,
  FormControl,
  MenuItem,
  Pagination,
  CircularProgress,
  TableCell,
  SvgIcon,
  Chip,
} from "@mui/material";

import StyledTableCell from "../common/StyledTableCell";
import StyledSelect from "../common/StyledSelect";
import StyledTableCellUnknown from "../common/StyledTableCellUnknown";
import {
  SearchOutlined,
  RefreshOutlined,
  ExpandLessOutlined,
  ExpandMoreOutlined,
  DeleteOutlined,
  WarningAmberOutlined,
} from "@mui/icons-material";

import Heading from "../common/Heading";
import { Chart } from "react-google-charts";

import BestPracticeModal from "./BestPracticeModal";
import { useNavigate } from "react-router-dom";

let hasFiltered: boolean = false;

const RefreshButton: any = styled(Button)({
  textTransform: "none",
  width: "115px",
  height: "40px",
  color: "primary",
  border: "1px solid #6F6F6F",
  fontWeight: "bold",
});

const BestPracticeButton: any = styled(Button)({
  textTransform: "none",
  fontSize: "16px",
  height: "30px",
});

const DeleteButton = styled(Button)({
  textTransform: "none",
  width: "160px",
  height: "40px",
});

const GoBackButton = styled(Button)({
  textTransform: "none",
  width: "160px",
  height: "40px",
});

const getSeverityColor = (severity: string) => {
  if (severity === "HIGH" || severity === "CRITICAL") {
    return "error.main";
  } else if (severity === "MEDIUM") {
    return "secondary.main";
  } else if (severity === "LOW") {
    return "success.main";
  } else {
    return "text";
  }
};

const getSeverityColorIcon = (severity: string) => {
  if (severity === "HIGH" || severity === "CRITICAL") {
    return "error";
  } else if (severity === "MEDIUM") {
    return "warning";
  } else if (severity === "LOW") {
    return "success";
  } else {
    return "info";
  }
};

const handleGoBack = () => {
  window.location.href = "/template-scanner?tabId=1";
};

const NoSortSvg = (
  <SvgIcon
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 8V16.5"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 14.5L6 16.5L4 14.5"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 10V1.5"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 3.5L10 1.5L12 3.5"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

const AscSvg = (
  <SvgIcon
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 8V16.5"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 14.5L6 16.5L4 14.5"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 10V1.5"
      stroke="#FF6700"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 3.5L10 1.5L12 3.5"
      stroke="#FF6700"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

const descSvg = (
  <SvgIcon
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 8V16.5"
      stroke="#FF6700"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 14.5L6 16.5L4 14.5"
      stroke="#FF6700"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 10V1.5"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 3.5L10 1.5L12 3.5"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

interface Control {
  id?: number;
  scan_result_id?: number;
  control_ref?: string;
  short_description?: string;
  long_description?: string;
  resource?: string;
  platform?: string;
  type?: string;
  behaviour?: string;
  severity?: string;
  automated_runbook?: string;
  remediation_url?: string;
  remediation_steps?: string;
  configuration_description?: string;
  remediation_path?: string;
  control_standard_names?: string[];
  standards: string[];
}

interface ScanResult {
  id?: string;
  resource?: string;
  check_type?: string;
  check_id?: number;
  controls?: Control[];
  finding?: string;
  severity?: string;
  file_line_range?: string;
  description?: string;
}

interface ExpandableRowInterface {
  item: ScanResult;
  rules: any;
  loading: boolean;
  index: number;
}

function ExpandableRow({
  item,
  rules,
  loading,
  index,
}: ExpandableRowInterface) {
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const getStandards = (controls: Array<Control>, control_mappings: any) => {
    let control_mapping_keys: Array<string> = Object.keys(control_mappings);
    let data: Array<string> = [];
    controls.forEach((control: Control) => {
      if (
        control.control_ref &&
        control_mapping_keys.includes(control.control_ref)
      ) {
        data.push(...control_mappings[control.control_ref]["standards"]);
      }
    });
    return new Set(data);
  };

  const displayContolMappings = () => {
    return (
      <>
        {loading && (
          <TableContainer sx={{ padding: "16px" }}>
            <Table>
              <TableCell align="center" colSpan={6}>
                <CircularProgress color="secondary" />
              </TableCell>
            </Table>
          </TableContainer>
        )}
        {item?.id &&
          Object.keys(rules["rule_mappings"]).length > 0 &&
          Object.keys(rules["rule_mappings"]).includes(item?.id) && (
            <TableContainer sx={{ padding: "16px" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCellUnknown width={"10%"}>
                      Control ID
                    </StyledTableCellUnknown>
                    <StyledTableCellUnknown>Description</StyledTableCellUnknown>
                    <StyledTableCellUnknown>
                      Control Severity
                    </StyledTableCellUnknown>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {item?.id &&
                    Object.keys(rules["rule_mappings"]).includes(item?.id) &&
                    Object.values<Control>(
                      rules["rule_mappings"][item?.id]["controls"]
                    ).map((control: Control, index: number) => {
                      console.log(control, item?.id, item.resource);
                      return (
                        <>
                          <TableRow key={index}>
                            <StyledTableCell>
                              <Typography variant="body1">
                                {control.control_ref}
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell>
                              <Typography variant="body1">
                                {control.short_description}
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell sx={{ display: "flex" }}>
                              <WarningAmberOutlined
                                color={getSeverityColorIcon(
                                  control.severity || "LOW"
                                )}
                                sx={{ p: "1px" }}
                              ></WarningAmberOutlined>
                              <Typography
                                color={getSeverityColor(
                                  control.severity || "LOW"
                                )}
                              >
                                {control.severity}
                              </Typography>
                            </StyledTableCell>
                          </TableRow>
                        </>
                      );
                    })}
                  {item?.id &&
                    Object.keys(rules["rule_mappings"]).includes(item?.id) && (
                      <TableRow key={`standard-${index}`}>
                        <StyledTableCell colSpan={3}>
                          {Array.from(
                            getStandards(
                              Object.values<Control>(
                                rules["rule_mappings"][item?.id]["controls"]
                              ),
                              rules["control_mappings"]
                            )
                          ).map((standard: string) => (
                            <Chip
                              sx={{
                                backgroundColor: "#6F6F6F",
                                color: "#fff",
                                borderRadius: 1,
                                margin: 0.5,
                              }}
                              label={standard}
                            />
                          ))}
                        </StyledTableCell>
                      </TableRow>
                    )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
      </>
    );
  };

  return (
    <>
      <BestPracticeModal
        open={modalOpen}
        checkType={item?.check_type || ""}
        resource={item?.resource || ""}
        description={item?.description || ""}
        checkId={item?.id || ""}
        onClose={handleCloseModal}
      />
      <TableRow key={index}>
        <StyledTableCell>
          <Typography
            variant="body1"
            style={{ wordWrap: "break-word", maxWidth: "250px" }}
          >
            {item.resource}
          </Typography>
        </StyledTableCell>
        <StyledTableCell>
          <Typography
            color={item.finding === "PASSED" ? "success.main" : "error.main"}
          >
            {item.finding}
          </Typography>
        </StyledTableCell>
        <StyledTableCell>
          <Typography color={getSeverityColor(item.severity || "LOW")}>
            {item.severity}
          </Typography>
        </StyledTableCell>
        <StyledTableCell>
          <Typography
            variant="body1"
            style={{ wordWrap: "break-word", maxWidth: "400px" }}
          >
            {item.description &&
              item.description.split("\n").map((item: string, idx: number) => {
                return (
                  <React.Fragment key={idx}>
                    {item}
                    <br />
                  </React.Fragment>
                );
              })}
          </Typography>
        </StyledTableCell>
        <StyledTableCell>
          <IconButton color="secondary" onClick={() => setOpen(!open)}>
            {open ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
          </IconButton>
        </StyledTableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Stack direction="row" spacing={2}>
              <Box sx={{ width: "100%" }}>
                <Grid
                  container
                  direction="row"
                  alignItems="space-between"
                  justifyContent="space-between"
                >
                  <Grid item sx={{ display: "block", margin: 1 }}>
                    <Typography variant="body1" align="left" gutterBottom>
                      <b>ID:</b> {item.id || "N/A"}
                    </Typography>
                    <Typography variant="body1" align="left" gutterBottom>
                      <b>Check Type:</b> {item.check_type || "N/A"}
                    </Typography>
                    <Typography variant="body1" align="left" gutterBottom>
                      <b>Lines:</b> {item.file_line_range || "N/A"}
                    </Typography>
                  </Grid>
                  <Grid item mt="35px">
                    <BestPracticeButton
                      variant="contained"
                      color="secondary"
                      type="submit"
                      onClick={handleOpenModal}
                    >
                      Generate Remediation Template
                    </BestPracticeButton>
                  </Grid>
                </Grid>
                {displayContolMappings()}
              </Box>
            </Stack>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

const ScannerFileResults = () => {
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [dataItems, setDataItems]: any[] = useState([]);
  const [fileSummary, setFileSummary]: any = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [tableUpdateData, setTableUpdateData] = useState(false);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [sortField, setSortField] = useState<keyof ScanResult | null>(null);

  const currentItems = hasFiltered ? filteredData : dataItems;
  const NASeverityCount = dataItems.filter((item: ScanResult) => {
    return item.severity === "N/A";
  }).length;

  const queryParemeters = new URLSearchParams(window.location.search);
  let summaryId = queryParemeters.get("id");

  const { rules, loading } = useGetTscanRules();

  const {
    data,
    loading: dataLoading,
    refetch,
  } = useGetTemplateEngineSecurityResponseQuery({
    variables: {
      processType: "getResult",
      summaryId: summaryId,
    },
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    context: {
      apiName: "template_scanner",
    },
  });

  summaryId = summaryId ? decodeURIComponent(summaryId) : "";
  if (!summaryId) {
    navigate("/template-scanner");
  }

  useEffect(() => {
    if (data) {
      const results = JSON.parse(
        data.getTemplateEngineSecurityResponse?.result?.results
      );
      const summary = JSON.parse(
        data.getTemplateEngineSecurityResponse?.result?.summary
      );
      setDataItems(results);
      setFileSummary(summary);
    }
    if (tableUpdateData) {
      setTableUpdateData(false);
    }
  }, [data]);

  useEffect(() => {
    if (tableUpdateData) {
      setSortField(null);
      refetch();
    }
  }, [tableUpdateData]);

  const sortIcon = (sort: boolean) => {
    if (sort && sortOrder === "asc") {
      return AscSvg;
    }
    if (sort && sortOrder === "desc") {
      return descSvg;
    }
    return NoSortSvg;
  };

  const requestSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Set filtered flag if not already set
    if (!hasFiltered) hasFiltered = true;

    const { value: searchValue } = event.target;
    const search = searchValue.toLowerCase();

    const found = dataItems.filter((item: ScanResult) => {
      const id = item.id?.toLowerCase() ?? "";
      const resource = item.resource?.toLowerCase() ?? "";
      const checkType = item.check_type?.toLowerCase() ?? "";
      const finding = item.finding?.toLowerCase() ?? "";
      const severity = item.severity?.toLowerCase() ?? "";
      const fileLineRange = item.file_line_range?.toLowerCase() ?? "";
      const description = item.description?.toLowerCase() ?? "";

      return (
        id.includes(search) ||
        resource.includes(search) ||
        checkType.includes(search) ||
        finding.includes(search) ||
        severity.includes(search) ||
        fileLineRange.includes(search) ||
        description.includes(search)
      );
    });
    setFilteredData(found);
  };

  const [DeleteTemplate] = useGetTemplateEngineSecurityResponseLazyQuery();

  const getSortedData = () => {
    if (!sortField) return currentItems;

    const sorted = [...currentItems].sort((a, b) => {
      const aValue = a[sortField] || "";
      const bValue = b[sortField] || "";

      if (aValue < bValue) return sortOrder === "asc" ? -1 : 1;
      if (aValue > bValue) return sortOrder === "asc" ? 1 : -1;
      return 0;
    });

    return sorted;
  };

  const handleTableSort = (fieldName: keyof ScanResult | null) => {
    if (sortField === fieldName) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortField(fieldName);
      setSortOrder("asc");
    }
  };

  const addLineBreaks = (string: string) => {
    if (typeof string === "string" && string) {
      const result = [];
      while (string.length) {
        result.push(string.substring(0, 25));
        string = string.substring(25);
      }
      return result;
    }
    return null;
  };

  const handleDeleteOnClick = () => {
    setDeleteLoading(true);
    DeleteTemplate({
      variables: {
        processType: "deleteTemplate",
        summaryList: summaryId,
      },
      context: {
        apiName: "template_scanner",
      },
      onCompleted: (data) => {
        setDeleteLoading(false);
        if (data.getTemplateEngineSecurityResponse?.msg === "success") {
          console.log("Successfully deleted template!");
          window.location.href = "/template-scanner";
        } else {
          console.log(
            "Error Deleting template. " +
              data.getTemplateEngineSecurityResponse?.error
          );
        }
      },
    });
  };

  let processedFilename = "";
  if (fileSummary.filename) {
    if (fileSummary.filename.length) {
      const lineBreaks = addLineBreaks(fileSummary.filename);
      processedFilename = lineBreaks
        ? lineBreaks.map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))
        : fileSummary.filename;
    }
  }

  return (
    <Container component="main">
      <Heading heading={"Template Scanner"} />
      <Stack spacing={2}>
        <Paper>
          <Grid container>
            <Grid item>
              <Stack spacing={1} padding="32px">
                <Typography variant="h5" fontWeight="bold" color="primary">
                  {processedFilename}
                </Typography>
                <Typography color="primary" fontWeight="bold">
                  {fileSummary.alias !== "N/A"
                    ? fileSummary.alias
                    : "No File Alias"}
                </Typography>
                <Box paddingLeft="16px">
                  <Typography color="success.main" fontWeight="bold">
                    Passed: {fileSummary.passed}
                  </Typography>
                  <Typography color="error.main" fontWeight="bold">
                    Critical: {fileSummary.critical}
                  </Typography>
                  <Typography color="#990099" fontWeight="bold">
                    High: {fileSummary.high}
                  </Typography>
                  <Typography color="secondary.main">
                    Medium: {fileSummary.medium}
                  </Typography>
                  <Typography color="primary.main" fontWeight="bold">
                    Low: {fileSummary.low}
                  </Typography>
                  <Typography color="primary.main" fontWeight="bold">
                    Unknown: {NASeverityCount}
                  </Typography>
                </Box>
              </Stack>
            </Grid>
            <Grid item justifyContent="center">
              <Chart
                width={"500px"}
                height={"300px"}
                chartType="PieChart"
                loader={<div>Loading Chart</div>}
                data={[
                  ["Task", "Summary"],
                  ["Passed", fileSummary.passed],
                  ["Critical", fileSummary.critical],
                  ["High", fileSummary.high],
                  ["Medium", fileSummary.medium],
                  ["Low", fileSummary.low],
                ]}
                options={{
                  colors: [
                    "#008A02",
                    "#E90000",
                    "#990099",
                    "#ff6700",
                    "#0e1956",
                  ],
                  pieHole: 0.4,
                }}
                rootProps={{ "data-testid": "1" }}
              />
            </Grid>
            <Grid item margin="32px" justifyContent="flex-end">
              <Stack direction="column" spacing={2}>
                {deleteLoading ? (
                  <CircularProgress color="secondary" />
                ) : (
                  <DeleteButton
                    startIcon={<DeleteOutlined />}
                    variant="contained"
                    color="secondary"
                    onClick={() => handleDeleteOnClick()}
                  >
                    Delete Results
                  </DeleteButton>
                )}
                <GoBackButton
                  variant="contained"
                  color="secondary"
                  onClick={() => handleGoBack()}
                >
                  Go Back
                </GoBackButton>
              </Stack>
            </Grid>
          </Grid>
          <Grid container flexDirection="row">
            <Paper
              component="form"
              elevation={0}
              sx={{
                p: "2px 4px",
                mb: 2,
                display: "flex",
                border: 0,
                boxShadow: 0,
                flexGrow: 1,
              }}
            >
              <TextField
                id="SearchTextField"
                label="Search"
                sx={{
                  width: "305px",
                  height: "40px",
                  marginLeft: "32px",
                  alignSelf: "center",
                }}
                onInput={requestSearch}
                onKeyPress={(e) => e.key === "Enter" && e.preventDefault()}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <SearchOutlined color="secondary" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Paper>
            <Paper
              component="form"
              elevation={0}
              sx={{
                p: "2px 4px",
                mb: 2,
                mt: "32px",
                display: "flex",
                justifyContent: "flex-end",
                flexGrow: 1,
              }}
            >
              <InputLabel
                id="page-size"
                sx={{
                  alignSelf: "center",
                  p: 1,
                  color: "#000000",
                  fw: "400",
                }}
              >
                Show
              </InputLabel>
              <FormControl
                sx={{ m: 1, marginRight: "32px", minWidth: 119 }}
                size="small"
              >
                <StyledSelect
                  labelId="page-size-select-label"
                  id="page-size-select"
                  defaultValue={20}
                  onChange={(event: any) => {
                    setRowsPerPage(event.target.value);
                  }}
                >
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </StyledSelect>
              </FormControl>
              <RefreshButton
                id="refresh-button"
                variant="outlined"
                sx={{ alignSelf: "center", p: 1, marginRight: "32px" }}
                onClick={() => setTableUpdateData(true)}
              >
                <RefreshOutlined
                  sx={{ width: "16px", height: "16px", margin: "8px" }}
                />
                Refresh
              </RefreshButton>
            </Paper>
          </Grid>
          <TableContainer sx={{ padding: "32px" }} component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell onClick={() => handleTableSort("resource")}>
                    Resource {sortIcon(sortField === "resource")}
                  </StyledTableCell>
                  <StyledTableCell onClick={() => handleTableSort("finding")}>
                    Finding {sortIcon(sortField === "finding")}
                  </StyledTableCell>
                  <StyledTableCell onClick={() => handleTableSort("severity")}>
                    Severity {sortIcon(sortField === "severity")}
                  </StyledTableCell>
                  <StyledTableCell>
                    Description / Recommended Remediation
                  </StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!dataLoading && !loading ? (
                  getSortedData()
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item: any, index: number) => {
                      return (
                        <>
                          <ExpandableRow
                            item={item}
                            rules={rules}
                            loading={loading}
                            index={index}
                          />
                        </>
                      );
                    })
                ) : (
                  <TableRow key={"progress-bar-1"}>
                    <StyledTableCell colSpan={4} align="center">
                      <CircularProgress color="secondary" />
                    </StyledTableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            variant="outlined"
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: "10px",
              color: "primary.main",
              borderColor: "primary.main",
            }}
            shape="rounded"
            count={Math.ceil(getSortedData().length / rowsPerPage)}
            page={page + 1}
            onChange={(event: React.ChangeEvent<unknown>, value: number) => {
              setPage(value - 1);
            }}
          />
        </Paper>
      </Stack>
    </Container>
  );
};

export default ScannerFileResults;
